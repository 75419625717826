import axios from "axios";

const baseUrl = 'https://api.bingosanvicente.com/api/v1';

const api = {

    getProducts (token) {
        return new Promise((resolve, reject) => {
            axios.get(baseUrl+'/productos',{headers:{Authorization: `Bearer ${token}`}})
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    createCompra(body, token){
        return new Promise((resolve, reject) => {
            axios.post(`${baseUrl}/compras`,body,{headers:{Authorization: `Bearer ${token}`}})
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    getCompras(token){
        return new Promise((resolve, reject) => {            
            axios.get(`${baseUrl}/compras`,{headers:{Authorization: `Bearer ${token}`}})
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    getComprasInforme(token){
        return new Promise((resolve, reject) => {            
            axios.get(`${baseUrl}/compras/informe`,{headers:{Authorization: `Bearer ${token}`}})
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    
    getEntradas(token){
        return new Promise((resolve, reject) => {            
            axios.get(`${baseUrl}/entradas`,{headers:{Authorization: `Bearer ${token}`}})
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    getEntradasInforme(token){
        return new Promise((resolve, reject) => {            
            axios.get(`${baseUrl}/entradas/informe`,{headers:{Authorization: `Bearer ${token}`}})
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    getEntradasXCompra(id, token){
        return new Promise((resolve, reject) => {
            axios.get(`${baseUrl}/entradas/entradasXCompra/${id}`,{headers:{Authorization: `Bearer ${token}`}})
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    getTicket(ruta, token){
        return new Promise((resolve, reject) => {
            axios.get(`${baseUrl}/ticket/${ruta}`,{responseType: 'blob',headers:{Authorization: `Bearer ${token}`}})
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    login(data){
        return new Promise((resolve, reject) => {
            axios.post(`${baseUrl}/login/`,data)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    reenviarMail(id, token){
        return new Promise((resolve, reject) => {
            axios.get(`${baseUrl}/reenviar/${id}`,{headers:{Authorization: `Bearer ${token}`}})
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

}

export default api;